import React from "react";
import  {withRouter, Link} from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Form, Button, FormGroup, Row, Col, InputGroup  ,Spinner} from "reactstrap";
import { renderTextField } from "./../wrapper";
import { _global } from "./../../helpers";
import { Auth } from "aws-amplify";
import { keyConstants } from "./../../constants";
import "./styles/login.scss";


class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      loader :  false
    };

    sessionStorage.removeItem("sessionTimeOut");
    sessionStorage.removeItem("_sessionToken");
    sessionStorage.removeItem("param");
  }

  onInputChange = (event) => {
    const { name, value } = event.currentTarget;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    sessionStorage._sessionToken = "";
    sessionStorage.setItem("loginClient", "cognito");
    const { username, password } = this.state;
    this.setState({...this.state , loader : true})
    if (username && password) {
      this.props.getLoginRequest();
      Auth.signIn(username, password)
        .then((user) => {
          this.props.getLoginSuccess(user);
          this.props.getUserInfo();
        })
        .catch((err) => {
          this.props.getLoginFailure(err);
          this.setState({...this.state , loader : false})
        });
    }
  };

  render() {
    const { handleSubmit } = this.props;
    const handleEnterKeyPress = (e) => {
      if (e.charCode === keyConstants.ENTER) {
        this.handleSubmit(_global.submit);
      }
    };


    return (
      <Row>
        <Col md={{ size: 12 }} className="loginColumn">
          <div className=" head-2 text-center margin-bottom-20">
            Welcome to the audit portal
          </div>
          <div className="subtitle text-center margin-bottom-30">
            Please log in to access the portal.
          </div>
          { this.props.location.search && this.props.location.search ==="?success=true" ? 
            <div className="success-text">
              Success! You've successfully created your account and now you can login
            </div>
             : this.props.location.search && this.props.location.search ==="?resetpwd=true" && (
              <div className="success-text">
                 Success! You've successfully reset your password, now you can login
               </div>
             )
          }
          <Form
            name="LoginForm"
            onSubmit={handleSubmit(_global.submit)}
            className="input-custom margin-top-20"
            onKeyPress={handleEnterKeyPress}
          >
            <FormGroup>
              <InputGroup className="margin-bottom-20">
                <Field
                  name="username"
                  label="Username"
                  placeholder="Enter Username"
                  component={renderTextField}
                  type="text"
                  className="login-input"
                  labelClassName={`custom-effect-label`}
                  onChange={this.onInputChange}
                ></Field>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Field
                name="password"
                label="Password"
                placeholder="Enter Password"
                component={renderTextField}
                onChange={this.onInputChange}
                type="password"
                className="login-input"
              ></Field>
              {/*
              <div className="responsive-new-user margin-bottom-20">
                <div>
                  <span className="new-user">New User? </span>
                  <Link className="new-user-reset" to="/signUp">
                    Click Here
                  </Link>
                </div>
                <Link className="forget-password-link" to="/forgetPassword">
                  Forgot Password ?
                </Link>
              </div>
              */}
            </FormGroup>
            {this.state.loader && (
            <div className="text-center overlay mb-10">
              <Spinner color="dark" className="loader-centivo" />
            </div>
           )}
            {this.props.authFailed ? (
              <div className="error text-center mb-10">{this.props.authFailed}</div>
            ) : null}
            <FormGroup>
              <Button
                type="button"
                color="primary"
                className="login-btn"
                onClick={this.handleSubmit}
                disabled={
                  !(
                    this.state.username.trim() &&
                    this.state.password.trim()
                  )
                }
              >
                Login
              </Button>
              {/* <div className="text-center margin-top-30">
                <em>For assistance call provider support at 833-559-7597.</em>
              </div> */}
            </FormGroup>
          </Form>
        </Col>
       
      </Row>
    );
  }
}

LoginForm = reduxForm({
  form: "login",
})(LoginForm);

export default withRouter(LoginForm);
