import React, { Component } from 'react';
import Auth from '@aws-amplify/auth';
import { Loader } from '../../components/wrapper';
import { withOAuth } from 'aws-amplify-react';

class Login extends Component {
  config = null

  constructor(props) {
    super(props);
    this.config = Auth.configure();
  }

  componentDidMount() {
    this.handleLogin();
  }

  handleLogin = () => {
    const { domain, redirectSignIn, responseType } = this.config.oauth;
    const clientId = this.config.userPoolWebClientId;
    sessionStorage.setItem('loginClient', 'azure');

    Auth.currentSession()
      .then(data => {
        sessionStorage.setItem('accessToken', data.accessToken.jwtToken);
        sessionStorage.setItem('idToken', data.idToken.jwtToken);
        sessionStorage.setItem('isLoggedIn', 'true');
        sessionStorage.setItem('userSignedIn', 'true');
        this.props.history.push('/claims');
      })
      .catch(err => {
        const url = 'https://' + domain + '/login?redirect_uri=' + redirectSignIn + '&response_type=' + responseType + '&client_id=' + clientId;
        window.location.assign(url);
      });
  };

  render() {
    return <Loader />;
  }
}

export default withOAuth(Login);
